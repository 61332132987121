.productSlider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 66px;
    padding: 0 25px;
    margin: 0 auto;
    box-sizing: border-box;

    // opacity: 0;
    // transform: translateY(30px);
    // transition: opacity 2s ease-out, transform 2s ease-out;
  
    // &.slideUp {
    //   opacity: 1;
    //   transform: translateY(0);
    // }

    &.dragging {
        cursor: pointer; // Set the cursor to pointer when dragging
    }

    .productslide {
        width: 100%;
        height: 750px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        perspective: 1500px; // Increase or decrease for effect depth
        transform-style: preserve-3d;
        transition: transform 1.2s ease-in-out;

        .left-col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            gap: 30px;
            transition: transform 0.1s ease-out;
            transform-style: preserve-3d;
            width: 100%; // Make left column responsive
            max-width: 670px; // Set maximum width for left column

            .product-title {
                position: relative;
                cursor: pointer;
                width: 100%; // Make title responsive
                height: 398px;
                flex-shrink: 0;
                border-radius: 32px;
                background: rgba(37, 37, 37, 0.32);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 0.1s ease-out;
                font-family: "HelveticaNowDisplay";
                overflow: hidden; // Ensure the gradient does not overflow outside the card
                padding:20px;
                box-sizing: border-box;

                .title {
                    z-index: 1;
                    width: 100%; // Make title text responsive
                    max-width: 552px;
                    height: 260px;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -1.28px;
                    background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.37) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: "HelveticaNowDisplay";
                }

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 152px; // Cover the entire width of the card
                    height: 152px; // Adjust the height as needed
                    background: linear-gradient(180deg, #FF416C 0%, #FE5400 100%);
                    filter: blur(60px); // Adjust the blur effect as needed
                    z-index: 0; // Place the gradient overlay behind the text
                    border-bottom-left-radius: 32px; // Match the border radius of the card
                }
            }

            .product-description {
                cursor: pointer;
                display: flex;
                width: 100%; // Make description responsive
                height: 270px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border-radius: 32px;
                background: rgba(37, 37, 37, 0.32);
                padding:25px;
                box-sizing: border-box;

                .description {
                    color: #878787;
                    text-align: justify;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    width: 100%; // Make description text responsive
                    max-width: 574px;
                    flex-shrink: 0;
                    margin: 0;
                    font-family: "HelveticaNowDisplay";
                }
            }
        }

        .product-image {
            width: 470px;
            height: 694px;
            min-width: 320px; // Set minimum width
            min-height: 450px; // Set minimum height
            border-radius: 32px;
            background: #1B1C1C;
            display: flex;
            align-items: center;
            justify-content: center;

            .product-gif {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 32px;
                flex-shrink: 0;
            }
        }
    }

    .indicators {
        display: inline-flex;
        padding: 6px;
        align-items: center;
        gap: 6px;
        border-radius: 12px;
        background: #303030;

        .indicator-buttons {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            background-color: #7A7A7A;
            transition: transform 0.2s ease, background-color 0.2s ease, width 0.2s ease, height 0.2s ease;
            font-family: "HelveticaNowDisplay";

            &.active {
                background-color: #D9D9D9;
                width: 12px;
                height: 12px;
            }

            &:not(.active) {
                background-color: #7A7A7A;
            }
        }
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideUp {
    animation: slideUp 2s ease-in-out forwards;
}

@media (max-width:1200px){
    .productSlider{
        .productslide{
            .left-col{
                .product-title{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-height: 250px;
                    .title{
                        font-size: 50px;
                        margin: 0;
                        height: 100%;
                    }
                }
                .product-description{
                    .description{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .productSlider {
        display: none;
    }
}
