.mobile_product_slider {
    display: none; /* Initially hide the component */
}

@media (max-width: 900px) {
    .mobile_product_slider {
        display: flex;
        position: relative;
        overflow: hidden;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 25px;
        margin: 0 auto;
        box-sizing: border-box;
        font-family: "HelveticaNowDisplay";
        gap:20px;


        .container_mobile_slider {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 650px;

            .product_image {
                width: 100%;
                max-width: 300px;
                min-height:500px;
                border-radius: 32px;
                background: #1B1C1C;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    border-radius: 32px;
                    flex-shrink: 0;
                }
            }

            .product_details {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap: 20px;
                padding: 25px;
                overflow: hidden;  // Ensure the gradient does not overflow outside the card
                position: relative;
                width: 90%;
                height: 100%;
                min-height:400px;
                max-height: 600px;

                flex-shrink: 0;
                border-radius: 32px;
                box-sizing: border-box;

                .product_title {
                    z-index: 1;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -1.28px;
                    background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.37) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;  // Cover the entire width of the card
                        height: 100px;  // Adjust the height as needed
                        background: linear-gradient(180deg, #FF416C 0%, #FE5400 100%);
                        filter: blur(120px);  // Adjust the blur effect as needed
                        z-index: 0;  // Place the gradient overlay behind the text
                        border-bottom-left-radius: 32px;  // Match the border radius of the card
                    }
                }
                .product_info {
                    color: #878787;
                    text-align: justify;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    flex-shrink: 0;
                }
            }
        }

        .indicators {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            background: #303030;
            border-radius: 12px;
            padding: 6px;

            .indicator-buttons {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: none;
                cursor: pointer;
                background-color: #7A7A7A;
                transition: transform 0.2s ease, background-color 0.2s ease, width 0.2s ease, height 0.2s ease;

                &.active {
                    background-color: #D9D9D9;
                    width: 12px;
                    height: 12px;
                }

                &:not(.active) {
                    background-color: #7A7A7A;
                }
            }
        }

        .prev-button, .next-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            font-size: 2em;
            cursor: pointer;
            z-index: 1;

            &.prev-button {
                left: 10px;
            }

            &.next-button {
                right: 10px;
            }
        }
    }
}

@media (max-width:700px){
    .mobile_product_slider{
        .container_mobile_slider{
            .product_details{
                .product_title{
                    font-size: 32px;
                }
            }
        }
    }
}