/* src/styles/fonts.css */

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Medium.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Black.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Black.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-BlackIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-BlackIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-BlackIta.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Bold.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-BoldIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-BoldIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-BoldIta.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ExtBdIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ExtBdIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ExtBlkIta.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ExtBlk.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ExtBlk.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ExtBlk.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ExtBlkIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ExtBlkIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ExtBlkIta.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ExtLt.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ExtLt.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ExtLt.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ExtLtIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ExtLtIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ExtLtIta.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Hairline.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Hairline.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Hairline.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-HairlineI.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-HairlineI.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-HairlineI.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Light.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Light.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-LightIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-LightIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-LightIta.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-MedIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-MedIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-MedIta.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-RegIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-RegIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-RegIta.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Regular.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Regular.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-Thin.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-Thin.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../public/fonts/HelveticaNowDisplay-ThinIta.woff2') format('woff2'),
       url('../public/fonts/HelveticaNowDisplay-ThinIta.woff') format('woff'),
       url('../public/fonts/HelveticaNowDisplay-ThinIta.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}



body,html{
  min-height:100%;
}

body {
  background-color: #1D1D1D; /* Set the background color */
  /* font-family: Arial, sans-serif; */
}


.app {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Ensure app takes up at least viewport height */
}
/* You can add more global styles here if needed */
