.videoPlayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
  box-sizing: border-box;

  .tryItOnYourOwn {
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    letter-spacing: -1.28px;
    background: linear-gradient(103deg, #FFF 2.33%, rgba(255, 255, 255, 0.37) 93.89%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'HelveticaNowDisplay';
  }

  button {
    background-color: transparent;
    border: none;
    color: #555;
    opacity: 0.8;
    cursor: pointer;
    font-family: 'HelveticaNowDisplay';
  }

  .icon {
    width: 40px;
    height: 40px;
    background-color: #1a1a1a;
    border-radius: 50%;
  }

  .videoContainer {
    position: relative;
    max-width: 1170px;
    width: 100%;
    min-height: 587px;
    flex-shrink: 0;
    border-radius: 32px;
    display: inline-block;
    overflow: hidden;
    transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);

    &.zoom-in {
      transform: scale(1.15);
      transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.zoom-original {
      transform: scale(1);
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
      display: block;
      transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .video-overlay-components {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .play-pause-controller {
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        .play-pause-button {
          margin-bottom: -125px;
          border: none;
        }

        .play-icon {
          border: none;
        }
      }

      .video-text-and-volume-components {
        display: flex;
        width: 92%;
        align-items: center;
        justify-content: space-between;

        .video-text-components {
          display: flex;
          align-items: end;
          justify-content: end;
          margin: 0 0 30px 15px;

          h3 {
            margin: 0;
            color: #FFF;
            text-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 90%;
            font-family: 'HelveticaNowDisplay';
            letter-spacing: -0.96px;
          }

          span {
            color: #E0E0E0;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'HelveticaNowDisplay';
          }
        }

        .volume-button {
          background: none;
          border: none;
          color: white;
          font-size: 1.5rem;
          cursor: pointer;
          outline: none;
          margin-top: 10px;
          .volume-icon {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .tryItOnYourOwn {
      font-size: 48px;
    }

    .videoContainer {
      max-width: 960px;
      min-height: 480px;

      &.zoom-in {
        transform: scale(1.1);
      }

      .video-overlay-components {
        .play-pause-controller {
          .play-pause-button {
            margin-bottom: -100px;
          }
        }

        .video-text-and-volume-components {
          .video-text-components {
            h3 {
              font-size: 36px;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 998px) {
    .tryItOnYourOwn {
      font-size: 36px;
    }

    .videoContainer {
      max-width: 800px;
      min-height: 400px;

      &.zoom-in {
        transform: scale(1.05);
      }

      .video-overlay-components {
        .play-pause-controller {
          .play-pause-button {
            margin-bottom: -75px;
          }
        }

        .video-text-and-volume-components {
          .video-text-components {
            h3 {
              font-size: 32px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 720px) {
    .tryItOnYourOwn {
      font-size: 28px;
    }

    .videoContainer {
      max-width: 100%;
      min-height: auto;
      border-radius: 16px;

      &.zoom-in {
        transform: scale(1.02);
      }

      video {
        border-radius: 16px;
      }

      .video-overlay-components {
        .play-pause-controller {
          .play-pause-button {
            margin-bottom: -50px;
          }
        }

        .video-text-and-volume-components {
          .video-text-components {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;

            h3 {
              font-size: 28px;
            }

            span {
              font-size: 12px;
            }
          }

          .volume-button {
            margin-top: 10px;

            .volume-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
