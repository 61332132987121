.team_component {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: 1170px;
      width: 100%;
      gap: 30px;
  
      .team_member {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 16px;
  
        .team_member_image {
          width: 100%;
          max-width: 370px;
          height: auto;
          aspect-ratio: 370 / 490;
          flex-shrink: 0;
          border-radius: 32px;
          background: -71.45px 0px / 132.432% 100% no-repeat;
          transition: transform 0.3s ease; /* Added transition for smooth effect */
        }
  
        .team_member_image:hover {
          transform: scale(1.015);
        }
  
        .name_and_role {
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          gap: 6px;
  
          .member_name {
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 25px */
            letter-spacing: -0.4px;
            margin: 0;
            font-family: 'HelveticaNowDisplay';
          }
          .member_role {
            color: #878787;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 20px */
            letter-spacing: -0.32px;
            margin: 0;
            font-family: 'HelveticaNowDisplay';
          }
        }
      }
    }
  }
  
  @media (max-width: 1200px) {
    .team_component .container .team_member .team_member_image {
      max-width: 300px;
      aspect-ratio: 300 / 400;
    }
  }
  
  @media (max-width: 992px) {
    .team_component .container .team_member .team_member_image {
      max-width: 380px;
      aspect-ratio: 250 / 333;
    }
  }
  
  @media (max-width: 768px) {
    .team_component .container .team_member .team_member_image {
      max-width: 350px;
      aspect-ratio: 200 / 266;
    }
  }
  
  @media (max-width: 576px) {
    .team_component .container .team_member .team_member_image {
      max-width: 300px;
      aspect-ratio: 150 / 200;
    }
  }
  