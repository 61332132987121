.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 17px;
  margin: 0 auto;
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;

  &.slideUp {
    opacity: 1;
    transform: translateY(0);
  }

  &.hidden {
    display: none;
  }

  .testimonials-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  
    .title {
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 105%; /* 67.2px */
      letter-spacing: -1.28px;
      background: linear-gradient(103deg, #fff 2.33%, rgba(255, 255, 255, 0.37) 93.89%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 'HelveticaNowDisplay';
    }
  
    .testimonials-card {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      width: 100%;
  
      .card {
        max-width: 560px;
        width: 100%;
        display: flex;
        padding: 48px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        border-radius: 32px;
        background: rgba(37, 37, 37, 0.32);
        min-height: 190px;
        box-sizing: border-box; /* Ensures padding is included in the width calculation */
  
        .profile-img {
          border-radius: 50%;
        }
  
        .testimonial-text {
          margin: 0;
          color: #878787;
          text-align: justify;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.4px;
          font-family: 'HelveticaNowDisplay';
        }
      }
    }
  
    .indicators-button {
      margin-top: 30px;
      display: inline-flex;
      padding: 6px;
      align-items: center;
      gap: 6px;
      border-radius: 12px;
      background: #303030;
  
      .indicator-button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        background-color: #7A7A7A;
        transition: transform 0.2s ease, background-color 0.2s ease, width 0.2s ease, height 0.2s ease;

        &.active {
          background-color: #D9D9D9;
          width: 12px;
          height: 12px;
        }

        &:not(.active) {
          background-color: #7A7A7A;
        }
      }
    }
  }

  @media (max-width: 998px) {
    .testimonials-container {
      padding: 0 25px;
      width:100%;

      .title {
        font-size: 36px; /* Adjust the font size for smaller screens */
      }

      .testimonials-card {
        flex-direction: column;
        gap: 20px;

        .card {
          max-width: 100%;
          width: 100%;
          padding: 24px;
        }
      }

      .indicators-button {
        margin-top: 20px;
        padding: 4px;

        .indicator-button {
          width: 8px;
          height: 8px;

          &.active {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
