@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.heroSection {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;

  .dark-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1); 
    opacity: 1;
    z-index: 3;
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    video,
    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 80%);
    }

    &.dark-background video,
    &.dark-background .hero-image {
      background-color: black;
    }

    .overlay-content {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
      padding-top: 48px;
      font-family: "HelveticaNowDisplay";
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 48px;
      box-sizing: border-box;

      &.dark-overlay {
        background: black;
      }

      .top-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        .menu-content-mobile-version {
          display: none;
          .menu-button {
            color: #FFF;
            font-size: 16px;
            font-weight: 500;
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-family: "HelveticaNowDisplay";
          }
        }

        .logo_image {
          width: 70.001px;
          height: 73px;
          flex-shrink: 0;
        }
      }

      .bottom-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: auto;

        .left-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .value-proposition {
            h1 {
              color: #FFF;
              font-size: 64px;
              font-weight: 500;
              line-height: 1.1;
              letter-spacing: -1.28px;
              font-family: "HelveticaNowDisplay";
            }
          }

          .getstartedforfree {
            text-decoration: none;
            display: inline-flex;
            padding: 16px 46px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 59px;
            background: linear-gradient(98deg, #FF416C -0.91%, #FE5400 100%);
            background-size: 200% 200%;
            backdrop-filter: blur(3px);
            color: rgba(255, 255, 255);
            font-size: 20px;
            font-weight: 500;
            border: none;
            cursor: pointer;
            font-family: "HelveticaNowDisplay";
            transition: background 0.2s;
          }
          
          .getstartedforfree:hover {
            animation: gradientAnimation 3s ease infinite;
          }
        }

        .right-container {
          display: none;
        }
      }
    }
  }

  .menu-content {
    position: absolute;
    top: 35%;
    right: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    z-index: 3;

    ul {
      list-style: none;

      li {
        text-align: right;
        margin: 14px 0;
        font-family: "HelveticaNowDisplay";

        .link {
          text-decoration: none;
          color: #FFF;
          font-size: 16px;
          font-weight: 400;
          font-family: "HelveticaNowDisplay";
        }

        button {
          background-color: transparent;
          border: none;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          font-family: "HelveticaNowDisplay";
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .heroSection {
    .video-wrapper {
      height: 100vh;
      .overlay-content {
        gap: 20px;
      }
    }
  }
}

@media (max-width: 992px) {
  .heroSection {
    .video-wrapper {
      height: 100vh;
      .overlay-content {
        gap: 20px;
        .bottom-div {
          .left-container {
            .value-proposition {
              h1 {
                font-size: 58px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .heroSection {
    .video-wrapper {
      height: 100vh;
      .overlay-content {
        width: 100%;
        padding: 24px;
        justify-content: space-between;

        .top-div {
          .menu-content-mobile-version {
            display: block;
            align-items: center;
            justify-content: center;
            .menu-button {
              color: #FFF;
              font-size: 16px;
              font-weight: 500;
              background-color: transparent;
              border: none;
              cursor: pointer;
              font-family: "HelveticaNowDisplay";
            }
          }
        }

        .bottom-div {
          align-items: flex-start;
          gap: 20px;
          margin-top: auto;

          .left-container {
            width: 100%;

            .value-proposition {
              h1 {
                font-size: 48px;
              }
            }

            .getstartedforfree {
              font-size: 16px;
              padding: 12px 36px;
            }
          }

          .right-container {
            width: 100%;
            justify-content: flex-start;
            display: none;

            .menu-button {
              font-size: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .menu-content {
      display: none; /* Hide menu by default on small screens */
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: rgba(0, 0, 0, 0.85); /* Optional: darken background */
      height: 100vh;
      top: 0;
      right: 0;
    }
    .menu-content.show {
      display: flex; /* Show menu when toggled */
    }
    .menu-content ul {
      padding: 0;
    }
    .menu-content ul li {
      text-align: center;
      margin: 20px 0; /* Increase gap */
    }
    .menu-content ul li .close-button {
      font-size: 18px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
}

@media (max-width: 660px) {
  .heroSection {
    .video-wrapper {
      height: 100vh;
      .overlay-content {
        justify-content: flex-end;
        gap: 20px;
        padding-bottom: 20px;

        .bottom-div {
          .left-container .value-proposition h1 {
            font-size: 36px;
          }
          .left-container .getstartedforfree {
            font-size: 14px;
            padding: 10px 30px;
          }
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .heroSection {
    .video-wrapper {
      .overlay-content {
        justify-content: center;
      }
    }
  }
}
