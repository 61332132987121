.productDescription {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 970px; /* Limits the maximum width to 970px */
    padding: 0 25px; /* Adds padding to maintain gaps on left and right */
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
    margin: 0 auto;
    opacity: 0; /* Initially hidden */
    transform: translateY(30px); /* Initially positioned below */

    &.slideUp {
        animation: slideUp 2s ease-in-out forwards;
    }

    .description_text {
        width: 100%; /* Ensures the inner element takes up the full width of the parent */
        text-align: center;
        font-size: 24px; /* Default font size */
        font-style: normal;
        font-weight: 500;
        line-height: 105%; /* 25.2px */
        letter-spacing: -0.48px;
        background: linear-gradient(124deg, #FFF 6%, rgba(255, 255, 255, 0.22) 80.68%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "HelveticaNowDisplay";
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Media Queries for different screen sizes */
@media (max-width: 1080px) {
    .productDescription {
        .description_text {
            /* No specific styles, can be used for future adjustments */
        }
    }
}

@media (max-width: 998px) {
    .productDescription {
        width: 85%;
        .description_text {
            font-size: 21px; /* Adjusts font size for medium screens */
        }
    }
}

@media (max-width: 660px) {
    .productDescription {
        .description_text {
            font-size: 20px; /* Adjusts font size for small screens */
        }
    }
}

/* Comments on Responsiveness:
1. The parent container (.productDescription) uses flexbox to center its content and maintain consistent padding.
2. The max-width property limits the width of the parent container to 970px, ensuring it doesn't grow too large on wide screens.
3. Padding is applied to the parent container to create gaps on the left and right sides, enhancing readability and layout consistency.
4. The box-sizing: border-box property ensures that the padding is included in the element's total width calculation.
5. The .description_text element is set to take up 100% of the width of its parent, ensuring it scales appropriately.
6. Font sizes are adjusted using media queries for different screen widths, ensuring text remains readable on various devices:
   - For screens 1080px wide or less, future adjustments can be added.
   - For screens 998px wide or less, the font size is reduced to 21px.
   - For screens 660px wide or less, the font size is reduced to 18px.
7. The slideUp animation is applied to create a smooth entry effect for the component, enhancing the user experience.
*/

.hidden {
    display: none;
}
