.aboutus_features {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding:0 25px;
    box-sizing: border-box;
    margin: 0 auto;
  
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
  
      .innovative_analysis {
        text-align: center;
        font-family: "HelveticaNowDisplay";
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%; /* 67.2px */
        letter-spacing: -1.28px;
        background: linear-gradient(103deg, #FFF 2.33%, rgba(255, 255, 255, 0.37) 93.89%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 10px;
      }
  
      .cards {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 30px;
        
  
        .aboutus_card {
          width:100%;
          max-width: 570px;
          min-height: 570px;
          // flex-shrink: 0;
          border-radius: 32px;
          position: relative;
          overflow: hidden;
  
          .aboutus_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 32px;
          }
  
          .overlay_content {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 30px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 37.68%, rgba(0, 0, 0, 0.80) 72.63%);
            border-radius: 32px;
  
            .analysis_title {
              color: #FFF;
              text-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
              font-family: "HelveticaNowDisplay";
              font-size: 48px;
              font-style: normal;
              font-weight: 500;
              line-height: 1; /* 0px */
              letter-spacing: -0.96px;
              width: 100%;
              margin: 0;
            }
  
            .analysis_title_description {
              color: #E0E0E0;
              text-align: justify;
        font-family: "HelveticaNowDisplay";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.32px;
            }
          }
        }
      }
    }
  }
  
@media (max-width:998px){
  .aboutus_features{
    .container{
      .cards{
        flex-direction: column;
        .aboutus_card{
          .overlay_content{
            .analysis_title{
              font-size: 32px;
            }
            .analysis_title_description{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:660px){ 
  .aboutus_features{
    .container{
      .innovative_analysis{
        font-size: 48px;
      }
    }
  }
}