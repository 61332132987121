.secure-transaction {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 25px; /* Add padding to maintain gaps on left and right */
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
    margin: 0 auto; /* Center the container horizontally */
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 2s ease-in-out, transform 2s ease-in-out;

    &.slideUp {
        opacity: 1;
        transform: translateY(0);
    }

    &.hidden {
        display: none;
      }
      
    .secure-transaction-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        width: 100%;
        flex-wrap: wrap; /* Allows wrapping of items when they overflow */

        .card1 {
            display: flex;
            justify-content: start;
            align-items: start;
            gap: 10px;
            width: 100%;
            max-width: 570px; /* Maximum width for larger screens */
            min-height: 230px; /* Set a constant height */
            flex-shrink: 0;
            border-radius: 32px;
            background: rgba(37, 37, 37, 0.32);
            padding: 48px;
            margin-bottom: 20px; /* Space between rows */
            box-sizing: border-box; /* Ensure padding is included in the width calculation */

            .secure-text {
                margin: 0;
                font-size: 64px;
                font-style: normal;
                font-weight: 500;
                line-height: 105%; /* 67.2px */
                letter-spacing: -1.28px;
                background: linear-gradient(108deg, #096C00 -1.63%, #81FF54 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "HelveticaNowDisplay";
            }
        }

        .card2 {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            position: relative;
            overflow: hidden; /* Ensure the gradient does not overflow outside the card */
            width: 100%;
            max-width: 570px; /* Maximum width for larger screens */
            min-height: 230px; /* Set a constant height */
            flex-shrink: 0;
            border-radius: 32px;
            background: rgba(37, 37, 37, 0.32);
            padding: 48px;
            margin-bottom: 20px; /* Space between rows */
            box-sizing: border-box; /* Ensure padding is included in the width calculation */

            &::before {
                content: "";
                border-radius: 196px;
                background: linear-gradient(180deg, #8C20E1 0%, #FE406C 100%);
                filter: blur(50.5px);
                position: absolute;
                top: 0;
                right: 0;
                width: 115px;
                height: 115px;
                z-index: 0;
                border-top-right-radius: 32px; /* Match the border radius of the card */
            }

            .payment-text {
                width: 100%;
                margin: 0;
                color: #878787;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.32px;
                display: flex;
                align-items: start;
                justify-content: start;
                font-family: "HelveticaNowDisplay";
            }

            .stripe-img {
                width: 146px;
                height: 69px;
                flex-shrink: 0;
                display: flex;
                align-items: start;
                justify-content: start;
                margin-top: 10px;
            }

            .transactions-logo {
                display: flex;
                align-items: end;
                justify-content: end;
                width: 100%;
                gap: 10px;
                margin-top: 20px;

                .logo-img {
                    width: 34px;
                    height: 24px;
                    flex-shrink: 0;
                    transition: transform 0.2s; /* Smooth transition for hover effect */
                }

                .logo-img:hover {
                    transform: scale(1.2); /* Scale up on hover */
                }
            }
        }
    }
}

/* Media Queries for responsiveness */
@media (max-width: 1250px) {
    .secure-transaction {
        .secure-transaction-container {
            flex-direction: column; /* Stack elements vertically for screens 1250px and below */
            align-items: center;
            gap: 15px; /* Adjust gap for smaller screens */
            width: 94%;

            .card1, .card2 {
                width: 100%; /* Make sure both cards take full width */
                max-width: none; /* Remove max-width constraint */
                height: auto; /* Adjust height for smaller screens */
                padding: 20px; /* Adjust padding for smaller screens */
                margin-bottom: 10px; /* Adjust spacing between cards */
            }

            .card1 .secure-text {
                font-size: 48px; /* Adjust font size for smaller screens */
            }
        }
    }
}

@media (min-width: 998px) {
    .secure-transaction {
        .secure-transaction-container {
            flex-direction: row; /* Arrange elements in a row for screens wider than 998px */
            align-items: center;

            .card1, .card2 {
                height: 230px; /* Set a constant height */
            }

            .card1 .secure-text {
                font-size: 64px; /* Restore default font size for larger screens */
            }
        }
    }
}

@media (max-width: 660px) {
    .secure-transaction {
        .secure-transaction-container {
            flex-direction: column; /* Stack elements vertically for screens 660px and below */
            align-items: center;
            gap: 15px; /* Adjust gap for smaller screens */

            .card1, .card2 {
                width: 100%; /* Make sure both cards take full width */
                max-width: none; /* Remove max-width constraint */
                height: auto; /* Adjust height for smaller screens */
                padding: 20px; /* Adjust padding for smaller screens */
                margin-bottom: 10px; /* Adjust spacing between cards */
            }

            .card1 .secure-text {
                font-size: 48px; /* Adjust font size for smaller screens */
            }
        }
    }
}

/* 
Explanation of Responsiveness:

1. **Initial Styles (Default):**
   - `.secure-transaction` centers its content using flexbox.
   - `.secure-transaction-container` also uses flexbox to align items and allows wrapping when necessary.
   - `.card1` and `.card2` have a default width of 100% for smaller screens and a max-width of 570px for larger screens. They have a constant height of 230px and are styled with padding, margin, and background properties.

2. **Media Query for max-width: 1250px:**
   - For screens 1250px and below, the `.secure-transaction-container` flex direction changes to `column`, stacking the cards vertically.
   - The cards take full width (`width: 100%`) and adjust their height to `auto` for better fit on smaller screens.
   - Padding and font sizes are adjusted for better readability and layout on smaller screens.

3. **Media Query for min-width: 998px:**
   - For screens wider than 998px, the `.secure-transaction-container` flex direction changes to `row`, arranging the cards side by side.
   - The cards each maintain a constant height of 230px.
   - The font size for `.secure-text` is restored to its original size.

4. **Media Query for max-width: 660px:**
   - For screens 660px and below, the layout is similar to the 1250px and below media query.
   - The `.secure-transaction-container` flex direction changes to `column`, stacking the cards vertically.
   - The cards take full width (`width: 100%`) and adjust their height to `auto`.
   - Additional adjustments are made for padding and spacing to ensure the content is readable and well-structured on small screens.
*/
