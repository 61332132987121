.version{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    padding: 0 25px;
    box-sizing: border-box;
    margin: 0 auto;

    .version-notes{
        width:100%;
        max-width:1170px;
        padding:48px;
        gap:35px;
        border-radius: 32px;
        background: rgba(37, 37, 37, 0.32);
        box-sizing: border-box;
    }

    // h1,h2,p{
    //     margin: 0;
    // }

    .title{
        color: #FFF;
        text-align: justify;
        // font-family: "Helvetica Now Display";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.96px;
        font-family: 'HelveticaNowDisplay';

    }

    .intro,.section-content, .closing{
        color: #878787;
        // font-family: "Helvetica Now Display";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
        font-family: 'HelveticaNowDisplay';

    }

    .section-title{
        color: #FFF;
        // font-family: "Helvetica Now Display";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.4px;
        font-family: 'HelveticaNowDisplay';

    }
}