.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // max-width: 1200px;
  min-height: 441px;
  flex-shrink: 0;
  gap: 30px;
  position: relative;
  padding: 0 25px; /* Adds padding to maintain gaps on left and right */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  margin: 80px auto 20px auto; /* Center the container horizontally */

  opacity: 0;
  transform: translateY(30px);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;

  &.slideUp {
    opacity: 1;
    transform: translateY(0);
  }

  &.hidden {
    display: none;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 32px;
    background: rgba(37, 37, 37, 0.32);
    width: 100%;
    max-width: 1170px;
    height: 100%;
    gap: 24px;
    padding: 48px 48px 24px 48px;
    box-sizing: border-box;

    .footer-top {
      display: flex;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      height: 100%;


      .footer-top-left {
        width: 40%;

        .footer-top-left-up {
          .pavanputra-logo {
            cursor: pointer;
          }

          .footer-left-text {
            .footer-main-text {
              color: #b4b4b4;
              text-align: justify;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.28px;
              font-family: "HelveticaNowDisplay";

            }
          }
        }

        .footer-top-left-down {
          gap: 12px;
          margin-top: 40px;
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: start;

          .social-media-icons {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            cursor: pointer;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }

      .footer-top-center {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .line-break-1,
        .line-break-2{
          display: none;
        }

        .footer-top-center-text {
          color: #fff;
          text-align: justify;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          margin: 0 0 10px 0;
          font-family: "HelveticaNowDisplay";

        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            color: #b4b4b4;
            text-align: justify;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            cursor: pointer;
            margin-bottom: 12px;

            
        .link {
          text-decoration: none;
          color: #b4b4b4;
          font-family: "HelveticaNowDisplay";

          // font-size: 16px;
          // font-weight: 400;
        }

          }
        }
      }

      .footer-top-right {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 12px;

        .subscribe-text {
          margin: 0;
          color: #fff;
          text-align: justify;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          font-family: "HelveticaNowDisplay";

        }

        .sub-div{
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: column;
          gap:10px;
          width:100%;

          .subscribe-input-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 300px;
            padding: 12px 24px;
            gap: 10px;
            border-radius: 27px;
            border: 1px solid #878787;
            background: #252525;
            font-family: "HelveticaNowDisplay";
            width:100%;

  
            .subscribe-input {
              width: 80%;
              height: 100%;
              border: none;
              background: transparent;
              color: #b4b4b4;
              font-family: "HelveticaNowDisplay";
              font-size: 14px;
  
              &::placeholder {
                color: #b4b4b4;
                opacity: 1;
                font-family: "HelveticaNowDisplay";
                font-size: 14px;
              }
  
              &:focus {
                outline: none;
              }
            }
  
            .placeholder-text {
              color: #b4b4b4;
              text-align: justify;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.28px;
              font-family: "HelveticaNowDisplay";

            }
  
            .subscribe-button {
              display: inline-flex;
              padding: 12px 24px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 38px;
              background: #333;
              color: #fff;
              text-align: justify;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.24px;
              border: none;
              cursor: pointer;
              font-family: "HelveticaNowDisplay";
            }

          }

          .response-message-div{
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: start;
            .response-message {
              // margin-top: 10px;
              font-size: 14px;
              font-family: "HelveticaNowDisplay";
              border-radius: 4px;
              // padding: 10px;
              text-align: center;
              margin: 0;
            }
    
            .response-message.success {
              color: #28a745;
              background-color: transparent;
              // border: 1px solid #c3e6cb;
            }
    
            .response-message.error {
              color: #dc3545;
              background-color: transparent;
              // border: 1px solid #f5c6cb;
            }  
          }

        }
        }
    }

    .line-break {
      width: 100%;
      max-width: 1068px;
      height: 0px;
      flex-shrink: 0;
      stroke-width: 0.5px;
      stroke: #878787;
      margin-top: 24px;
    }

    .footer-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1068px;
      width: 100%;

      .footer-bottom-left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;

        .footer-bottom-text {
          color: #878787;
          text-align: justify;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.24px;
          cursor: pointer;
          font-family: "HelveticaNowDisplay";
          text-decoration: none;

        }
      }

      .footer-bottom-right {
        .footer-bottom-text {
          color: #878787;
          text-align: justify;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.24px;
          font-family: "HelveticaNowDisplay";

        }
      }
    }
  }

  @media (max-width: 1100px) {
    .container {
      padding: 48px 25px 24px 25px;

      .footer-top {
        flex-direction: column;
        gap: 20px;

        .footer-top-left,
        .footer-top-right {
          width: 100%;
          align-items: flex-start;
        }

        .footer-top-right {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .subscribe-text {
            text-transform: uppercase;
            font-size: 21px;
          }
        }

        .footer-top-center {
          width: 100%;;
          .line-break-1,
          .line-break-2{
            display: block;
            width: 100%;
            max-width: 1068px;
            height: 0px;
            flex-shrink: 0;
            stroke-width: 0.5px;
            stroke: #878787;
          }
          .line-break-1{
            margin-bottom: 18px;
          }
        }
      }

      .line-break {
        width: 100%;
      }

      // .footer-bottom {
      //   flex-direction: column;
      //   gap: 12px;
      // }

      .subscribe-input-div {
        width: 100%;
        padding: 12px;
      }
    }
  }

  @media (max-width: 660px) {
    .container {
      padding: 24px;

      .footer-top {
        gap: 20px;

        .footer-top-left,
        .footer-top-right {
          align-items: flex-start;
        }

        .footer-top-right {
          flex-direction: column;
        }

        .footer-top-left {
          .footer-top-left-down {
            .social-media-icons {
              width: 20px;
              height: 20px;
            }
          }
        }

        .footer-top-right {
          .sub-div{
            .subscribe-input-div{
              max-width:235px;
            }
          }
        }
      }

      .footer-bottom {
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}

/* Comments on responsiveness:
1. The @media query for max-width: 1100px ensures the footer-top elements are stacked vertically, and the footer-top-center section is hidden for medium-sized screens.
2. For screens narrower than 660px, the footer-top-left-down section's social media icons are enlarged slightly for better visibility, and the footer-top-right section is stacked vertically for small screens.
3. The subscribe-input-div adjusts its width to fit the available space on smaller screens, ensuring it remains responsive and user-friendly.
4. The container padding is adjusted to maintain consistent spacing within the footer for different screen sizes.
5. The line-break width is set to 100% to ensure it fits within the container across various screen sizes.
6. Flex-direction adjustments for footer-bottom ensure proper alignment and spacing of elements on smaller screens. */
