.aboutus_slider{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    padding:0 25px;
    margin:0 auto;
    box-sizing: border-box;

    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap:30px;

        .slider_image{
            width:100%;
            max-width: 470px;
            min-height: 694px;
            border-radius: 32px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
            overflow: hidden;

            .main_image{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .right_div{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap:30px;

            .right-top-div{
                display: flex;
                align-items: center;
                justify-content: center;
                width:100%;
                max-width: 670px;
                height: 398px;
                border-radius: 32px;
                padding:48px;
                box-sizing: border-box;
                background: rgba(37, 37, 37, 0.32);
                position: relative;
                overflow: hidden;

                .our_mission_title{
                    font-family: "HelveticaNowDisplay";
                    font-size: 64px;
                    font-weight: 500;
                    letter-spacing: -1.28px;
                    margin: 0;
                    width: 100%;
                    text-align: left;
                    background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.37) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 125px;
                    height: 125px;
                    background: linear-gradient(180deg, #FF416C 0%, #FE5400 100%);
                    filter: blur(60px);
                    z-index: 0;
                    border-bottom-left-radius: 32px;
                }
            }

            .right-bottom-div{
                display: flex;
                max-width: 670px;
                min-height: 270px;
                padding: 48px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                box-sizing: border-box;
                border-radius: 32px;
                background: rgba(37, 37, 37, 0.32);

                .our_mission_description{
                    color: #878787;
                    text-align: justify;
                    font-family: "HelveticaNowDisplay";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.4px;

                    .highlight{
                        color: #FFF;
                        font-family: "HelveticaNowDisplay";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.4px;
                    }
                }
            }
        }
    }
}

@media (max-width:660px){
    .aboutus_slider{
        .container{
            .slider_image{
                width: 100%;
                max-width: 450px;
                min-height: auto;
                border-radius: 32px;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
                overflow: hidden;

                .main_image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .right_div{
                .right-top-div{
                    max-width: 450px;
                    // padding: 20px;

                    .our_mission_title{
                        font-size: 36px;
                        letter-spacing: -0.64px;
                    }
                }
                .right-bottom-div{
                    max-width: 450px;
                    // padding: 20px;

                    .our_mission_description{
                        font-size: 18px;
                        letter-spacing: -0.32px;

                        .highlight{
                            font-size: 16px;
                            letter-spacing: -0.32px;
                        }
                    }
                }
            }
        }
    }
}
