.account-deletion-request {
    .content {
        max-width: 800px;
        margin: 2rem auto;
        padding: 0 1rem;

        @media (max-width: 768px) {
            margin: 1rem auto;
            padding: 0 15px;
        }

        h1 {
            color: #fff;
            margin-bottom: 1.5rem;
            text-align: center;
            font-family: 'HelveticaNowDisplay', sans-serif;
            font-weight: 700;

            @media (max-width: 768px) {
                font-size: 24px;
                margin-bottom: 1rem;
            }
        }

        .info-box {
            background-color: #fff3f3;
            border: 1px solid #ffcdd2;
            border-radius: 4px;
            padding: 1.5rem;
            margin-bottom: 2rem;
            font-family: 'HelveticaNowDisplay', sans-serif;
            font-weight: 400;

            @media (max-width: 768px) {
                padding: 1rem;
                margin-bottom: 1.5rem;
            }

            p {
                font-weight: 500;
                margin-bottom: 1rem;
                font-family: 'HelveticaNowDisplay', sans-serif;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }

            ul {
                list-style-type: disc;
                margin-left: 1.5rem;

                @media (max-width: 768px) {
                    margin-left: 1rem;
                }

                li {
                    margin-bottom: 0.5rem;
                    color: #d32f2f;
                    font-family: 'HelveticaNowDisplay', sans-serif;
                    font-weight: 400;

                    @media (max-width: 768px) {
                        font-size: 14px;
                        margin-bottom: 0.3rem;
                    }
                }
            }
        }

        .form-container {
            background: white;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            font-family: 'HelveticaNowDisplay', sans-serif;
            font-weight: 400;

            @media (max-width: 768px) {
                border-radius: 6px;
            }

            iframe {
                width: 100%;
                height: 700px;

                @media (max-width: 768px) {
                    height: 600px; // Slightly shorter on mobile
                }

                @media (max-width: 480px) {
                    height: 500px; // Even shorter on very small devices
                }
            }
        }
    }
} 