.contactus_form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
  
    .contactus_form_container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1170px;
      width: 100%;
      gap: 43px;
  
      .mobile_div {
        display: none; /* Initially hidden */
      }
  
      .left_side {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 24px;
        min-height: 723px;
  
        .title {
          color: #FFF;
          font-size: 64px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%;
          letter-spacing: -1.28px;
          text-align: left;
          margin: 0;
          font-family: "HelveticaNowDisplay";
        }
  
        .para-div {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: start;
          gap: 6px;
  
          .para {
            color: #878787;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            font-family: "HelveticaNowDisplay";
          }
  
          .para2 {
            color: #878787;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            font-family: "HelveticaNowDisplay";
  
  
            .email {
              color: #FE5400;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.32px;
              font-family: "HelveticaNowDisplay";
            }
          }
        }
      }
  
      .right_side {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 670px;
        min-height: 723px;
        flex-shrink: 0;
        border-radius: 32px;
        padding: 48px;
        background: rgba(37, 37, 37, 0.32);
        box-sizing: border-box;
        gap: 48px;
  
        .title2 {
          width: 100%;
          text-align: left;
          margin: 0;
          font-size: 48px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.96px;
          background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.37) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          z-index: 22;
          font-family: "HelveticaNowDisplay";
        }
  
        .contact-form {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 24px;
          width: 100%;
  
          .input {
            display: flex;
            width: 100%;
            padding: 20px 24px;
            align-items: center;
            gap: 10px;
            border-radius: 121px;
            border: 1px solid #878787;
            background: #252525;
            color: #b4b4b4;
            text-align: justify;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            font-family: "HelveticaNowDisplay";
          }
  
          .input_message {
            display: flex;
            width: 100%;
            height: 225px;
            padding: 20px 24px;
            align-items: flex-start;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 24px;
            border: 1px solid #878787;
            background: #252525;
            color: #b4b4b4;
            text-align: justify;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            font-family: "HelveticaNowDisplay";
          }
  
          .submit-button {
            cursor: pointer;
            display: inline-flex;
            padding: 16px 46px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 59px;
            border: 1px solid #333;
            background: rgba(26, 26, 26, 0.80);
            backdrop-filter: blur(8.300000190734863px);
            color: rgba(255, 255, 255, 0.75);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.4px;
            font-family: "HelveticaNowDisplay";
          }
        }
  
        .response-message {
        //   margin-top: 20px;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          font-family: "HelveticaNowDisplay";
        }
  
        .response-message.success {
        //   background-color: #e0ffe0;
          color: #008000;
          border: none;
        }
  
        .response-message.error {
        //   background-color: #ffe0e0;
          color: #ff0000;
          border: none;

        }
      }
    }
  }
  
  @media (max-width: 1300px) {
    .contactus_form .contactus_form_container {
      flex-direction: column;
  
      .left_side {
        display: none;
      }
  
      .mobile_div {
        display: block; /* Displayed when screen width is below 1300px */
        width: 100%;
        max-width: 670px;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 24px;
  
        .title3 {
          color: #FFF;
          font-size: 56px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%;
          letter-spacing: -1.28px;
          text-align: left;
          margin: 0;
        }
  
        .mobile-para-div {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: start;
          gap: 6px;
  
          .mobile-para {
            color: #878787;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
          }
  
          .mobile-para2 {
            color: #878787;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
  
            .email {
              color: #fe5400;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.32px;
            }
          }
        }
      }
  
      .right_side .title2 {
        font-size: 40px;
      }
    }
  }
  
  @media (max-width: 660px) {
    .contactus_form .contactus_form_container .left_side {
      min-height: 380px;
      max-height: 723px;
  
      .title {
        font-size: 52px;
      }
    }
  }
  