.featureShowCase {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 25px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;

  &.slideUp {
    opacity: 1;
    transform: translateY(0);
  }

  .features_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1170px;
    gap: 30px;
    position: relative;

    .point {
      cursor: pointer;
      transition: transform 0.9s ease;
      position: relative;
      border-radius: 32px;
      padding: 35px 0;
      gap: 10px;
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      background-color: inherit;
      width: 100%; /* Set initial width to 100% */
      max-width: 570px;

      &:hover {
        transform: scale(1.03);
      }

      &.selected {
        z-index: 1000;
        transform: translate(-50%, -50%) scale(1); // Center the selected card
        transition: transform 0.9s ease;
        position: fixed;
        top: 50%;
        left: 50%;
        width: auto; /* Maintain original width */
        max-width: 40%;
        background-color: black; /* Set background color to black */
        color: white; /* Set text color to white */

        .description {
          display: block;
        }
      }

      .selected .title {
        text-align: start; /* Align text to the start */
        margin: 0;
        width: 90%;
      }

      .description {
        display: none;
        margin-top: 10px;
        font-size: 16px;
      }

      .title {
        margin: 0;
      }

      span {
        padding: 0 30px;
        color: white;
        font-size: 16px;
      }
    }

    .left-column-features,
    .right-column-features {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      width: 50%;

      .point-one,
      .point-two,
      .point-three,
      .point-four {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 32px;

        .title {
          font-size: 64px;
          font-style: normal;
          font-weight: 500;
          line-height: 105%; /* 67.2px */
          letter-spacing: -1.28px;
          text-align: left;
          width: 88%;
          font-family: "HelveticaNowDisplay";
        }
      }

      .point-one {
        background: linear-gradient(111deg, #E7BD59 -8.1%, #FFE3A1 106.06%);
        .title {
          background: linear-gradient(108deg, #AA7800 -1.63%, rgba(76, 54, 0, 0.40) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .point-two,
      .point-four {
        background: rgba(37, 37, 37, 0.32);
        .title {
          background: linear-gradient(103deg, #FFF 2.33%, rgba(255, 255, 255, 0.37) 93.89%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .point-three {
        background: rgba(37, 37, 37, 0.32);
        .title {
          background: linear-gradient(108deg, #8E20E5 -1.63%, #E7BD59 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: "HelveticaNowDisplay";
        }
      }

      .point-four {
        .title {
          background: linear-gradient(180deg, #E7BD59 0%, #FFE3A1 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .subtitle {
          width: 88%;
          color: #878787;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.32px;
          display: flex;
          align-items: start;
          justify-content: start;
          margin-bottom: -2px;
          flex-direction: column;
          font-family: "HelveticaNowDisplay";

          .time {
            margin: 0;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .subtext {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .featureShowCase {
    .features_container {
      .left-column-features,
      .right-column-features {
        width: 50%;
        .point-one,
        .point-two,
        .point-three,
        .point-four {
          .title {
            font-size: 48px; /* Adjust font size */
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .featureShowCase {
    .features_container {
      .left-column-features,
      .right-column-features {
        width: 50%;
        .point-one,
        .point-two,
        .point-three,
        .point-four {
          .title {
            font-size: 46px; /* Adjust font size */
          }
        }
      }
    }
  }
}

@media (max-width: 998px) {
  .featureShowCase {
    .features_container {
      .point {
        width: 70%; /* Further reduce the width of the cards */
        max-width: 450px; /* Adjust max-width */
        .title {
          font-size: 36px; /* Further reduce font size */
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .featureShowCase {
    .features_container {
      .point {
        width: 60%; /* Further reduce the width of the cards */
        max-width: 400px; /* Adjust max-width */
        .title {
          font-size: 32px; /* Further reduce font size */
        }
      }
    }
  }
}

@media (max-width: 660px) {
  .featureShowCase {
    .features_container {
      flex-direction: column;

      .left-column-features,
      .right-column-features {
        width: 100%;
        .point-one,
        .point-two,
        .point-three,
        .point-four {
          width: 100%;
          max-width: none;
          .title {
            font-size: 32px; /* Adjust font size */
          }
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
}
