.trackerSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;

  opacity: 0;
  transform: translateY(30px);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;

  &.slideUp {
    opacity: 1;
    transform: translateY(0);
  }

  .tracker-text {
    color: rgba(255, 255, 255, 0.69);
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.28px;
    font-family: 'HelveticaNowDisplay';
    text-align: center;
  }

  .slider-container {
    z-index: 2;
    width: 100%;
    max-width: 1170px;
    height: 700px;
    border-radius: 32px;
    overflow: hidden;

    .sliderImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }
  }

  /* Media queries for responsiveness */
  @media (max-width: 1440px) {
    .tracker-text {
      font-size: 56px;
    }

    .slider-container {
      height: 650px;
    }
  }

  @media (max-width: 1280px) {
    .tracker-text {
      font-size: 48px;
    }

    .slider-container {
      height: 600px;
    }
  }

  @media (max-width: 1080px) {
    .tracker-text {
      font-size: 40px;
    }

    .slider-container {
      height: 550px;
    }
  }

  @media (max-width: 998px) {
    .tracker-text {
      font-size: 32px;
    }

    .slider-container {
      height: 400px;
    }
  }

  @media (max-width: 768px) {
    .tracker-text {
      font-size: 28px;
    }

    .slider-container {
      height: 350px;
    }
  }

  @media (max-width: 660px) {
    .tracker-text {
      font-size: 24px;
    }

    .slider-container {
      width: 90%;
      height: 300px;

      .sliderImage {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 480px) {
    .tracker-text {
      font-size: 20px;
    }

    .slider-container {
      height: 220px;
    }
  }

  @media (max-width: 360px) {
    .tracker-text {
      font-size: 18px;
    }

    .slider-container {
      height: 200px;
    }
  }
}
